import React, { useState, useEffect, useRef } from 'react';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import * as KeyCode from 'keycode-js';
import api from 'services/Api';

import CommentItem from '../CommentItem/index';
import { Popover, List, Tooltip } from '@material-ui/core';
import { PATH_TICKET_SERVICE } from '../../constants';
import useIsAssigned from 'hooks/services/useIsAssigned';
import {
  convertToDifferenceFromNow,
  convertToLocalTimestamp,
} from 'utils/time';

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    height: '93%',
    maxHeight: '93%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      display: 'flex',
    },
    boxShadow: '0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '10px',
    marginBottom: '1rem',
  },
  commentsListContainer: {
    flexGrow: 1,
    flexShrink: 1,
    // see reason for flexBasis=1px,  https://stackoverflow.com/questions/52487743/prevent-flex-item-from-exceeding-parent-height-and-make-scroll-bar-work
    flexBasis: '1px',
    overflowY: 'auto',
    minHeight: 0,
  },
  grid: {
    padding: '0.3rem 2%',
  },
  commentLeftHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  commentRightHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  commentSection: {
    padding: '2%',
    width: '100%',
    flexWrap: 'wrap',
    whiteSpace: 'pre-line',
  },
  button: {
    padding: 0,
  },
  date: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    color: '#808CA3',
  },
  topic: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#626799',
    paddingLeft: '20px',
  },
  card: {
    background: '#FFFFFF',
    border: '1px solid #B5BDE9',
    boxSizing: 'border-box',
    borderRadius: '6px',
    boxShadow: 'none',
    overflowX: 'auto',
    scrollbarWidth: 'none',
  },
  popOver: { background: '#001847', color: '#FF7C7C', borderRadius: '4px' },
  popOverIcon: {
    color: '#FF7C7C',
  },
  popOverShadow: {
    boxShadow: '0px 4px 19px rgba(0, 0, 0, 0.25)',
  },
}));

const Comments = ({
  ticketData,
  ticketComments,
  getCurrentCommentValue = () => {},
}) => {
  const classes = useStyles();
  const scrollRef = useRef(null);
  const [comment, setComment] = useState([]);
  const [commentValue, setCommentValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [edit, setEdit] = React.useState(null);
  const [buttonEdit, setButtonEdit] = React.useState(null);
  const [isSendCommentEnabled, setIsSendCommentEnabled] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const setEnqueueSnackbar = (msg, snackerVariant) => {
    enqueueSnackbar(msg, {
      variant: snackerVariant,
      autoHideDuration: 3000,
    });
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    getCurrentCommentValue(commentValue);
  }, [commentValue]);

  useEffect(() => {
    if (ticketComments) {
      setComment(ticketComments.reverse());
    }
  }, [ticketComments]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: 'smooth' });
    }
  }, [comment]);

  const { mutateAsync: isAssigned } = useIsAssigned(
    ticketData.entitlementId,
    ticketData.ticketId
  );

  const addComment = async (event) => {
    if (event.key === KeyCode.CODE_ENTER && event.target.value !== '') {
      event.preventDefault();
      const newComment = {
        commentText: event.target.value,
      };
      addCommentApi(newComment);
    }
  };

  const addCommentButton = () => {
    const newComment = {
      commentText: commentValue,
    };
    addCommentApi(newComment);
  };

  const addCommentApi = async (newComment) => {
    setIsSendCommentEnabled(false);
    const checkAssignee = {
      isAssigned: true,
    };
    try {
      const addComment = await api.post(
        `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/comments`,
        JSON.stringify(newComment)
      );
      const result = await addComment.json();
      if (result) {
        isAssigned(checkAssignee);
      }
      setComment([...comment, result.data.comment]);
      setCommentValue('');
      setEnqueueSnackbar('Comment sent successfully', 'success');
    } catch (error) {
      setEnqueueSnackbar('Failed to send comment', 'error');
      return Promise.reject(error);
    } finally {
      setIsSendCommentEnabled(true);
    }
  };
  function convertGMTtoEST(gmtDateString) {
    const gmtDate = new Date(gmtDateString);
    const utcTime = gmtDate.getTime();
    const estOffset = -4 * 60 * 60 * 1000;
    const estDate = new Date(utcTime + estOffset);
    return estDate.toLocaleString();
  }
  const updateComment = (event) => {
    if (event.key === KeyCode.CODE_ENTER) {
      event.preventDefault();
      updateCommentApi(event.target.id, event.target.value);
    }
  };

  const updateCommentApi = async (commentId, commentText) => {
    try {
      const newComment = {
        commentText,
      };
      const updateComment = await api.put(
        `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/comments/${commentId}`,
        JSON.stringify(newComment)
      );
      const result = await updateComment.json();
      const commentIndex = comment.findIndex(
        (element) => element.commentId === commentId
      );
      let newArray = [...comment];
      newArray[commentIndex] = result.data.comment;
      setComment(newArray);
    } catch (error) {
      return Promise.reject(error);
    }
    closeEdit();
  };

  const openMenu = (commentId) => (e) => {
    setButtonEdit(commentId);
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setButtonEdit(null);
    setAnchorEl(null);
  };

  const openEdit = () => {
    setEdit(buttonEdit);
  };

  const closeEdit = () => {
    setButtonEdit(null);
    setEdit(null);
  };

  const closeAfterDelete = () => {
    setAnchorEl(null);
    closeEdit();
  };

  const deleteComment = async () => {
    try {
      const commentId = buttonEdit;
      await api.delete(
        `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/comments/${commentId}`
      );
      const commentIndex = comment.findIndex(
        (element) => element.commentId === commentId
      );
      let newArray = [...comment];
      newArray.splice(commentIndex, 1);
      setComment(newArray);
      closeAfterDelete();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const checkIfEditable = (lastUpdate) => {
    const date = new Date();
    const dbDate = new Date(lastUpdate + ' GMT');

    const FIVE_MIN = 5 * 60 * 1000;

    //Returns false if comments was made more than 5 minutes ago
    return date - dbDate <= FIVE_MIN;
  };

  return (
    <>
      <Grid container className={classes.container} style={{ height: '80%' }}>
        <p className={classes.topic}>Comments</p>
        <Grid item className={classes.commentsListContainer} xs={12}>
          {comment.map(
            ({ commentText, lastUpdate, commentId, createdUserName }) => (
              <Grid key={commentId} container className={classes.grid}>
                <Grid item xs={12}>
                  <Card key={commentId} className={classes.card}>
                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      style={{ padding: '0px 2%', flexDirection: 'row' }}
                      direction="row"
                      flexWrap="nowrap"
                      xs={12}
                    >
                      <Grid
                        item
                        alignItems="center"
                        style={{ padding: '0.5rem 0', display: 'flex' }}
                      >
                        {createdUserName}
                      </Grid>

                      <Grid
                        alignContent="center"
                        alignItems="center"
                        style={{ display: 'flex' }}
                      >
                        <div className={classes.date}>
                          <Tooltip
                            title={convertToLocalTimestamp(`${lastUpdate} UTC`)}
                            placement="top"
                            arrow
                          >
                            <p>
                              {convertToDifferenceFromNow(`${lastUpdate} UTC`)}
                            </p>
                          </Tooltip>
                          {/* {}
                          {' (EST) '} */}
                        </div>
                        {checkIfEditable(lastUpdate) && (
                          <>
                            <IconButton
                              aria-haspopup="true"
                              onClick={openMenu(commentId)}
                            >
                              <MoreHorizOutlinedIcon />
                            </IconButton>
                            <Popover
                              anchorEl={anchorEl}
                              open={open}
                              onClose={closeMenu}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              classes={{ paper: classes.popOverShadow }}
                            >
                              <List
                                component="nav"
                                aria-label="main mailbox folders"
                                className={classes.popOver}
                              >
                                <ListItem button onClick={openEdit}>
                                  <ListItemIcon>
                                    <EditOutlinedIcon
                                      className={classes.popOverIcon}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary="Edit" />
                                </ListItem>
                                <ListItem button onClick={deleteComment}>
                                  <ListItemIcon>
                                    <DeleteForeverOutlinedIcon
                                      className={classes.popOverIcon}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary="Delete" />
                                </ListItem>
                              </List>
                            </Popover>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Divider variant="middle" />
                    {edit === commentId ? (
                      <Grid container item className={classes.commentSection}>
                        <TextField
                          onKeyDown={updateComment}
                          variant="outlined"
                          fullWidth
                          defaultValue={commentText}
                          id={commentId}
                          onBlur={closeEdit}
                        />
                      </Grid>
                    ) : (
                      <Grid container item className={classes.commentSection}>
                        {commentText}
                      </Grid>
                    )}
                  </Card>
                </Grid>
              </Grid>
            )
          )}
          <div ref={scrollRef} style={{ height: '1rem' }} />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CommentItem
          xs={12}
          addComment={addComment}
          addCommentButton={addCommentButton}
          commentValue={commentValue}
          setCommentValue={setCommentValue}
          enabled={isSendCommentEnabled}
        />
      </Grid>
    </>
  );
};

export default React.memo(Comments);
